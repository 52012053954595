<template>
  <div class="w-10/12 lg:w-9/12 text-left font-proximaNormal">
    <h2 class="text-2xl">{{ $t("social_login_verification_title") }}</h2>
    <h5 class="text-sm my-4">
      {{ $t("social_login_verification_info_text") }}
    </h5>
    <div>
      <div class="relative mb-1">
        <FormError :errors="errorPhone" />
        <FormError :errors="errorEmail" />

        <vue-tel-input
          required
          :inputOptions="options"
          validCharactersOnly
          v-model="phoneNumber"
          @validate="telValidate"
        />
      </div>
    </div>
    <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" />
    <SubmitButton
      :title="$t('global_verify')"
      background="bg-primary"
      @click.native="submitForm"
      v-else
    />
  </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import Loader from "../loader/Loader.vue";
import FormError from "../warning/FormError.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";

import {
  checkSocialPhoneNumber,
  existSocialEmail,
  getEmailSocialRegistration,
} from "../../services/authenticationService";
import { sendOtp } from "../../services/smsService";

export default {
  components: {
    SubmitButton,
    Loader,
    FormError,
    VueTelInput,
  },
  data() {
    return {
      phoneNumber: "",
      email_address: "",
      provider: "",
      shortphonenumber: "",
      errorPhone: [],
      errorEmail: [],
      message: "",
      options: {
        placeholder: this.$t("global_phone_number_placeholder"),
      },
    };
  },

  computed: {
    showLoaderComp() {
      return this.$store.state.showLoaderState;
    },
  },

  async created() {
    this.errorPhone = [];
    this.errorEmail = [];

    const urlSocial =
      this.$route.params["code5"] +
      "/" +
      this.$route.params["code6"] +
      "/" +
      window.location.search;

    try {
      const { data } = await getEmailSocialRegistration(urlSocial);
      console.log(data);

      this.email_address = data.email_address;
      this.provider = data.provider;
      this.role = data.role;
      localStorage.setItem("role", data.role);
      console.log("provider", this.provider);
    } catch (error) {
      if (error.response && error.response.status == 302) {
        console.log("/////////////", error);
        //this.$router.push({ path: "/primaryregistration" });
        //this.errorEmail.push("A user already exists with this email address");
      } else {
        this.errorEmail.push("invalid grant");
      }
    }

    try {
      const { data } = await existSocialEmail(
        this.email_address,
        this.provider
      );
      console.log(data);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status == 302) {
        this.$router.push({ path: "/primaryregistration" });
        //this.errorEmail.push("A user already exists with this email address");
      } /* else if (error.response && error.response.status == 404) {
        this.errorEmail.push("A user already exists with this email address");
      } */
    }
  },
  methods: {
    telValidate(telnumber) {
      this.phoneNumber = telnumber.number;
    },

    async submitForm() {
      this.errorPhone = [];

      if (!this.phoneNumber) {
        this.errorPhone.push("Phone number required");
        this.phoneReq = false;
      } else {
        this.pinReq = true;
        //this.showLoader = true;
        try {
          await checkSocialPhoneNumber(this.phoneNumber);
        } catch (error) {
          if (error.response && error.response.status == 404) {
            console.log(this.email_address, this.phoneNumber);
            try {
              const account = {
                phonenumber: this.phoneNumber,
                email: this.email_address,
                lang: "EN",
                role: localStorage.getItem("role"),
                servicePracticeType: 0, //this.servicePracticeType
              };

              const { data } = await sendOtp(account);
              console.log(data);
              this.message = ""; //data.message;
              this.$router.push({
                name: "OtpVerificationSocial", //"MagicalLinkVerification",
                params: {
                  email_address: this.email_address,
                  phone_number: this.phoneNumber,
                  provider: this.provider,
                  servicePracticeType: 0,
                  message: this.message,
                },
              });
            } catch (error) {
              this.$store.commit("showLoader", false);
              console.log(error);
              if (error.response && error.response.status == 403) {
                this.errorPhone.push(
                  "sms sending service is not available, please retry later."
                );
              } else {
                this.errorEmail.push("API internal error");
              }
            }
          } else if (error.response && error.response.status == 302) {
            this.$store.commit("showLoader", false);
            this.errorPhone.push(
              "A user already exists with this phone number"
            );
          }
        }
      }
    },
  },
};
</script>

<style>
.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
